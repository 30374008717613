import axiosInstance from "../../utils/axiosInstance";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import detectTimeout from "../../utils/detectTimeout";

const StartSession = ({toastAlert, finalAction, projectId }) => {
    const [sessionName, setSessionName] = useState("");
    const [sessionDescription, setSessionDescription] = useState("");
    const { id } = useParams();
    const newId = id ? id : projectId;

    const navigate = useNavigate();

    const handle_err_response = (res) => {
        if (!detectTimeout(res, navigate)) { 
            toastAlert(res.response?.data.message);
            finalAction();
        }
    }

    const handleCreateSession = () => {
        axiosInstance
            .post(`/api/session/create/${newId}`, {
                session_name: sessionName,
                session_description: sessionDescription,
            })
            .then((res) => {
                toastAlert(res.data.message);
                finalAction();
            })
            .catch(handle_err_response);
    };

    return (
        <div className="bg-white lg:rounded-l-[50px] start__session h-full w-full flex justify-center items-center">
            <div className="session__inner    border-[1px] rounded-[8px] bordered-[#F8FAFF] shadow-black px-[28px] py-[20px] flex flex-col items-center w-[593px]">
                <h1 className="text-center text-3xl font-bold mb-5">Start Session</h1>
                <input
                    className="block pl-4 border h-[40px] w-[288px] rounded-[7px] mb-[12px]"
                    type="text"
                    placeholder="Session name"
                    onChange={(e) => setSessionName(e.target.value)}
                />
                <input
                    className="block pl-4 border h-[40px] w-[288px] rounded-[7px] mb-[12px]"
                    type="text"
                    placeholder="Session Description"
                    onChange={(e) => setSessionDescription(e.target.value)}
                />
                <button
                    onClick={handleCreateSession}
                    className="commissioner min-w-[121px] bg-[#F1F6FF] text-[20px] leading-[38px font-[500] rounded-[8px] p-[8px] mb-[10px]"
                >
                    Start
                </button>
            </div>
        </div>
    );
};

export default StartSession;
