import React, { useContext, useState } from "react";
import "./Register.css";
import detectTimeout from "../../utils/detectTimeout";
import loginLogo from "../../assets/login_logo.png";
import axiosInstance from "../../utils/axiosInstance";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/AuthProvider";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {
    
    const { my_user, setMyUser } = useContext(UserContext);
    const navigate = useNavigate();
    const [error, setError] = useState("");
 
    const toastAlert = (msg) => toast(msg, {containerId:"Login"});

    const handle_err_response = (res) => {
        if (!detectTimeout(res,navigate))
            {toastAlert(res.response.data.message)}
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        const data = {
            username: e.target.username.value,
            password: e.target.password.value,
        };

        axiosInstance
            .post("/api/user/login", data)
            .then((response) => {
                setError("");
                axiosInstance
                .post("/api/user/", data)
                    .then((response) => {
                        setMyUser(response.data.user)
                        navigate("/dashboard/project");
                    })
                    .catch(handle_err_response);     
            })
            .catch((err) => {
                if (err.response.data.message) {
                    setError("Please put a valid username and password");
                }
            });
    };

    return (
        <div className=" py-[60px]">
            <ToastContainer enableMultiContainer containerId={'Login'}/>
            <div className="login_container shadow">
                <img
                    width={150}
                    className=" mx-auto mb-[31px]"
                    src={loginLogo}
                    alt=""
                />
                <p className="outfit text-center text-[#C9312E] text-[36px] font-[500] mb-[30px] font_family">
                    Login to your account
                </p>
                <p className="text-red-600">{error}</p>
                <form onSubmit={handleSubmit}>
                    <div className="mb-[25px]">
                        <label className=" outfit text-[20px] font-[300] mb-[10px] block">
                            Username <sup className="text-[#C9312E]">*</sup>
                        </label>
                        <input
                            type="text"
                            className="input input-bordered w-full h-[50px]"
                            required
                            name="username"
                        />
                    </div>
                    <div className="mb-[25px]">
                        <label className="outfit text-[20px] font-[300] mb-[10px] block">
                            Password <sup className="text-[#C9312E]">*</sup>
                        </label>
                        <input
                            type="password"
                            className="input input-bordered w-full h-[50px]"
                            required
                            name="password"
                        />
                    </div>

                    <div className="flex justify-center gap-[30px] mb-[25px]">
                    <button
                            className="outfit bg-[#3853A4] p-3 lg:py-[17px] lg:px-[50px] text-white text-[15px] lg:text-[20px] font-[500] rounded-[5px]"
                            type="submit"
                        >
                            Register
                        </button>

                        <button
                            className="outfit bg-[#3853A4] p-3 lg:py-[17px] lg:px-[50px] text-white text-[15px] lg:text-[20px] font-[500] rounded-[5px]"
                            type="submit"
                        >
                            Log In
                        </button>
                    </div>
                    <p className="pointer text-[20px] font-[300] text-center">
                        <span className="key outfit">
                            I forgot my password or lost my key
                        </span>
                    </p>
                </form>
            </div>
        </div>
    );
};

export default Login;
