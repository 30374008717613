import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../utils/axiosInstance";
import detectTimeout from "../../utils/detectTimeout";

const ProjectCopy = ({ toastAlert, finalAction, projectId, projectDetails }) => {
    const navigate = useNavigate();
    const [projectName, setProjectName] = useState("");
    const [projectDescription, setDescription] = useState("");
    const [base64Image, setBase64Image] = useState("");
    
    const inputRef = useRef();

    const handle_err_response = (res) => {
        if (!detectTimeout(res, navigate)) { 
            toastAlert(res.response?.data.message);
            finalAction();
        }
    }

    const onFileChangeCapture = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            setBase64Image(reader.result);
        };

        reader.readAsDataURL(file);
    };

    //reload projects list
    useEffect(() => {
        if (projectDetails)
        {
            setProjectName(projectDetails.name);
            setDescription(projectDetails.description);
            setBase64Image(projectDetails.image)
        }
    }, [projectDetails]);

    const { id } = useParams();
    const newId = id ? id : projectId;

    const handleAccept = () => {
        const data = {
            project_name: projectName,
            project_description: projectDescription,
            project_image: base64Image,
        };

        axiosInstance
            .post(`/api/project/copy/${id}`, data)
            .then((res) => {
                toastAlert(res.data.message);
                navigate(`/dashboard/projectDetails/${res.data.project_id}`); //go to new project
                finalAction(); //will hide this dialog
            })
            .catch(handle_err_response);
    };

    const inputHandler = () => {
        inputRef.current.click();
    };

    return (
        <div className="bg-white lg:rounded-l-[50px] project__copy w-full h-full flex justify-center items-center">
            <div className="copy__inner    border-[1px] rounded-[8px] bordered-[#F8FAFF] shadow-black px-[28px] py-[20px] flex flex-col items-center w-[593px]">
                <h1 className="text-3xl font-bold mb-5 ">Copy this project</h1>
                Name
                <input
                    className="block pl-4 border h-[40px] w-[288px] rounded-[7px] mb-[12px]"
                    type="text"
                    onChange={(e) => setProjectName(e.target.value)}
                    value={projectName}
                />
                Description
                <input
                    className="block pl-4 border h-[40px] w-[288px] rounded-[7px] mb-[12px] "
                    type="text"
                    value={projectDescription}
                    onChange={(e) => setDescription(e.target.value)}
                />

                <div className="copy__img border-[1px] overflow-hidden w-[302px] bordered rounded-[8px]">
                    <input
                        type="file"
                        ref={inputRef}
                        accept="image/*"
                        onChangeCapture={onFileChangeCapture}
                        className="file-input w-full hidden    border-0 bg-white"
                    />
                 
                    <div
                        className="profile relative cursor-pointer "
                    >
                        {base64Image &&<img
                            className="relative z-[99] h-[192px] w-full"
                            src={base64Image}
                            alt="image"
                        />   }  
                        <button className="underline commissioner w-full bg-[#F8FAFF] text-[18px] font-[500] text-center py-[15px] mt-[-10px] z-[9]"
                        onClick={inputHandler}>
                            Pick new image
                        </button>
                        <button className="underline commissioner w-full bg-[#F8FAFF] text-[18px] font-[500] text-center py-[15px] mt-[-10px] z-[9]"
                            onClick={()=>setBase64Image("")}>
                            Use random image
                        </button>
                        <button className="underline commissioner w-full bg-[#F8FAFF] text-[18px] font-[500] text-center py-[15px] mt-[-10px] z-[9]"
                            onClick={()=>setBase64Image(projectDetails.image)}>
                            Use same image
                        </button>
                    </div>                
                </div>
                <button
                    onClick={handleAccept}
                    className="mt-4 commissioner min-w-[121px] bg-[#F1F6FF] text-[20px] leading-[38px font-[500] rounded-[8px] p-[8px] mb-[10px]"
                >
                    Accept
                </button>
            </div>
        </div>
    );
};

export default ProjectCopy;
