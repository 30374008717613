// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Mange__User td {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

/*.Mange__User tr{
    margin-bottom: 20px;
} */

.Mange__User table {
  width: 100%;
  border-collapse: collapse;
}

.Mange__User thead {
  background: linear-gradient(
    0deg,
    #3853a4 0%,
    rgba(56, 83, 164, 0.858042) 53.13%,
    rgba(56, 83, 164, 0.55) 100%
  );
  border-radius: 4px;
  /* padding: 21px 13px; */
  color: #fff;
}

.Mange__User thead th {
  margin-bottom: 20px;
}

.Mange__User td {
  padding: 12px 0;
  text-align: left;
  box-sizing: border-box;
}

.Mange__User th {
  padding: 12px;
}

.Mange__User tr:nth-child(even) {
}

.Mange__User tr:not(:first-child) {
  margin-top: 20px;
}

.node__info tr td {
  text-align: center;
  font-size: 16px;
  padding:5px;
  background: linear-gradient(
    0deg,
    rgba(56, 83, 164, 0.2) 0%,
    rgba(56, 83, 164, 0.171608) 53.13%,
    rgba(56, 83, 164, 0.11) 100%
  );
}
table thead th,
td {
  font-family: "Commissioner", sans-serif;
}
`, "",{"version":3,"sources":["webpack://./src/components/MangeUser/manage.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;;GAEG;;AAEH;EACE,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE;;;;;GAKC;EACD,kBAAkB;EAClB,wBAAwB;EACxB,WAAW;AACb;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,sBAAsB;AACxB;;AAEA;EACE,aAAa;AACf;;AAEA;AACA;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,WAAW;EACX;;;;;GAKC;AACH;AACA;;EAEE,uCAAuC;AACzC","sourcesContent":[".Mange__User td {\r\n  font-size: 16px;\r\n  font-weight: 500;\r\n  line-height: 20px;\r\n}\r\n\r\n/*.Mange__User tr{\r\n    margin-bottom: 20px;\r\n} */\r\n\r\n.Mange__User table {\r\n  width: 100%;\r\n  border-collapse: collapse;\r\n}\r\n\r\n.Mange__User thead {\r\n  background: linear-gradient(\r\n    0deg,\r\n    #3853a4 0%,\r\n    rgba(56, 83, 164, 0.858042) 53.13%,\r\n    rgba(56, 83, 164, 0.55) 100%\r\n  );\r\n  border-radius: 4px;\r\n  /* padding: 21px 13px; */\r\n  color: #fff;\r\n}\r\n\r\n.Mange__User thead th {\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.Mange__User td {\r\n  padding: 12px 0;\r\n  text-align: left;\r\n  box-sizing: border-box;\r\n}\r\n\r\n.Mange__User th {\r\n  padding: 12px;\r\n}\r\n\r\n.Mange__User tr:nth-child(even) {\r\n}\r\n\r\n.Mange__User tr:not(:first-child) {\r\n  margin-top: 20px;\r\n}\r\n\r\n.node__info tr td {\r\n  text-align: center;\r\n  font-size: 16px;\r\n  padding:5px;\r\n  background: linear-gradient(\r\n    0deg,\r\n    rgba(56, 83, 164, 0.2) 0%,\r\n    rgba(56, 83, 164, 0.171608) 53.13%,\r\n    rgba(56, 83, 164, 0.11) 100%\r\n  );\r\n}\r\ntable thead th,\r\ntd {\r\n  font-family: \"Commissioner\", sans-serif;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
