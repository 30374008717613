import React from "react";

const Notfound = () => {
  return (
    <div className="flex justify-center my-auto">
      <img
        src="/404.jpg"  alt=""
      />
    </div>
  );
};

export default Notfound;
