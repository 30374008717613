import axiosInstance from "../../utils/axiosInstance";
import React, { useEffect, useState } from "react";
import detectTimeout from "../../utils/detectTimeout";
import { useNavigate, useParams } from "react-router-dom";

const ProjectLog = () => {
    const [projectLog, setProjectLog] = useState([]);
    const { id } = useParams();
    const navigate = useNavigate();

    const handle_err_response = (res) => {
        if (!detectTimeout(res, navigate))
        {}
    }

    useEffect(() => {
        axiosInstance
            .post(`/api/project/logs/${id}`)
            .then((response) => setProjectLog(response.data.log_strings))
            .catch(handle_err_response);
    }, [id]);

    return (
        <div className="node__info bg-[#FFFBFB] lg:rounded-l-[50px] h-full overflow-x-auto lg:py-[20px] lg:pt-[20px] lg:px-[20px] p-4">
            <h1 className="text-3xl font-bold mb-5">Project Logs</h1>
            <table >
                <tbody>
                    <tr className="bg-blue-200 h-16">
                        <th>Log</th>
                        <th>Time</th>
                    </tr>

                    {projectLog.map((i, index) => (
                        <tr key={index}>
                            <td
                                dangerouslySetInnerHTML={{
                                    __html: i.log,
                                }}
                            ></td>
                            <td>{i.time}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ProjectLog;
