import axiosInstance from "../../utils/axiosInstance";
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import detectTimeout from "../../utils/detectTimeout";

const UserLog = () => {
    const [userLog, setUserLog] = useState([]);
    const navigate = useNavigate();
    const { id } = useParams();

    const handle_err_response = (res) => {
        detectTimeout(res, navigate); //no good errors to show just get out of here
    }

    useEffect(() => {
        axiosInstance
            .post(`/api/user/logs/${id}`)
            .then((response) => setUserLog(response.data.log_strings))
            .catch(handle_err_response);
    }, [id]);

    return (
        <div className="node__info bg-[#FFFBFB] lg:rounded-l-[50px] h-full overflow-x-auto lg:py-[20px] lg:pt-[20px] lg:px-[10px] p-4">
            <h1 className="text-3xl font-bold mb-5">User Logs</h1>
            <table className="w-[1032px] px-0">
                <tbody>
                <tr className="bg-blue-200 h-16">
                    <th>Log</th>
                    <th>Time</th>
                </tr>

                {userLog.map((i, index) => (
                    <tr key={index}>
                        <td
                            dangerouslySetInnerHTML={{
                                __html: i.log,
                            }}
                        ></td>
                        <td>{i.time}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default UserLog;
