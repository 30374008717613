import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../utils/axiosInstance";
import detectTimeout from "../../utils/detectTimeout";

const SmbMount = ({ toastAlert, finalAction, projectId, projectDetails }) => {
    const navigate = useNavigate();
    const [server, setServer] = useState("");
    const [share, setShare] = useState("");
    const [mount, setMount] = useState("");
    const [username, setUsername] = useState("test");
    const [password, setPassword] = useState("");

    const { id } = useParams();
    const newId = id ? id : projectId;

    const handle_err_response = (res) => {
        if (!detectTimeout(res, navigate)) { 
            toastAlert(res.response?.data.message);
            finalAction();
        }
    }

    const handleAccept = () => {
        const data = {
            username,
            password,
            server,
            share,
            mount,
        };

        axiosInstance
            .post(`/api/project/create_mount/${id}`, data)
            .then((res) => {
                toastAlert(res.data.message);
                finalAction(); //will hide this dialog
            })
            .catch(handle_err_response);
    };

    return (
        <div className="bg-white lg:rounded-l-[50px] project__copy w-full h-full flex justify-center items-center">
            <div className="copy__inner  w-full  border-[1px] rounded-[8px] bordered-[#F8FAFF] shadow-black px-[28px] py-[20px] flex flex-col items-center w-[593px]">
                
                <div className="mb-[33px] w-full flow-root">
                    <label className="flex outfit text-[20px] font-[300] mb-[10px] block py-[10px] px-[1em]">
                        Username
                    </label>
                    <input
                        type="text"
                        className="flex input input-bordered w-[300px] h-[56px]"
                        name="username"
                        onChange={(event)=>setUsername(event.target.value)}
                        value={username}
                    />

                    <label className="flex outfit text-[20px] font-[300] mb-[10px] block py-[10px] px-[1em]">
                        Password
                    </label>
                    <input
                        type="text"
                        className="flex input input-bordered w-[300px] h-[56px]"
                        name="username"
                        onChange={(event)=>setPassword(event.target.value)}
                        value={password}
                    />

                    <label className="outfit text-[20px] font-[300] mb-[10px] block py-[10px] px-[1em]">
                        Server
                    </label>
                    <input
                        type="text"
                        className="input input-bordered w-[300px] h-[56px]"
                        name="username"
                        onChange={(event)=>setServer(event.target.value)}
                        value={server}
                    />

                    <label className="outfit text-[20px] font-[300] mb-[10px] block py-[10px] px-[1em]">
                        Share
                    </label>
                    <input
                        type="text"
                        className="input input-bordered w-[300px] h-[56px]"
                        name="username"
                        onChange={(event)=>setShare(event.target.value)}
                        value={share}
                    />

                    <label className="outfit text-[20px] font-[300] mb-[10px] block py-[10px] px-[1em]">
                        Mount Name
                    </label>
                    <input
                        type="text"
                        className="input input-bordered w-[300px] h-[56px]"
                        name="username"
                        onChange={(event)=>setMount(event.target.value)}
                        value={mount}
                    />
                </div>
                
                <button
                    onClick={handleAccept}
                    className="mt-4 commissioner min-w-[121px] bg-[#F1F6FF] text-[20px] leading-[38px font-[500] rounded-[8px] p-[8px] mb-[10px]"
                >
                    Accept
                </button>
            </div>
        </div>
    );
};

export default SmbMount;
