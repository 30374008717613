import axiosInstance from "../../utils/axiosInstance";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./account.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UserContext } from "../../context/AuthProvider";
import detectTimeout from "../../utils/detectTimeout";

const Account = () => {
    const { my_user, setMyUser } = useContext(UserContext);
    const [first_name, setFN] = useState("");
    const [last_name, setLN] = useState("");
    const [email, setEmail] = useState("");
    const [changingFN, setChangeFN] = useState(false);
    const [changingLN, setChangeLN] = useState(false);
    const [changingEmail, setChangeEmail] = useState(false);
    const [file, setFile] = useState(null);
    const [base64Image, setBase64Image] = useState("");
    const navigate = useNavigate();

    const toastAlert = (msg) => {toast(msg, {containerId:"Account"})};

    const handle_err_response = (res) => {
        if (!detectTimeout(res, navigate))
           {toastAlert(res.response.data.message)}
    }

    const inputRef = useRef();
    const onFileChangeCapture = (e) => {
        setFile(URL.createObjectURL(e.target.files[0]));
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            setBase64Image(reader.result);
        };

        reader.readAsDataURL(file);
    };

    const inputHandler = () => {
        inputRef.current.click();
    };

    const requestUser = () =>
    {
        axiosInstance
            .post( "/api/user/")
            .then((response) => setMyUser(response.data.user))
            .catch(handle_err_response);
    }

    const updateUser= () =>
    {
        if (my_user)
        {   
            //frustratingly we can maybe see a null in one of these fields and then react complains
            (my_user.first_name) ? setFN( my_user?.first_name):  setFN("");
            (my_user.last_name)  ? setLN(my_user?.last_name ):  setLN("");
            (my_user.email)      ? setEmail( my_user?.email):  setEmail("");
        }
    }

    useEffect(() => {
        if(base64Image) handleSubmit();
     }, [base64Image]);

    useEffect(() => {
        requestUser();
     }, []);

    useEffect(() => {
       updateUser();
    }, [my_user]);
    
    const handleSubmit = () => {
        // const password = e.target.password.value;
        const data = {
            first_name,
            last_name,
            email,
            user_image: base64Image ? base64Image : undefined,
        };
        axiosInstance
            .post("/api/user/edit", data,)
            .then((res) => {
                toastAlert(res.data.message);
                setMyUser(res.data.user);
                setChangeEmail(false);
                setChangeFN(false);
                setChangeLN(false);            
            })
            .catch( (e) =>
            {
                updateUser();
                handle_err_response(e)
                setChangeEmail(false);
                setChangeFN(false);
                setChangeLN(false);    
            });
    };

    const handleLogOut = () => {
        axiosInstance
        .post("/api/user/logout")
        .then((res) => {          
             navigate("/");
        });
    };

    return (
        <div className="user__set bg-white lg:rounded-l-[50px] h-full p-[40px] md:px-[100px] md:py-[40px]">
            <ToastContainer enableMultiContainer containerId={'Account'}/>
            <div className="user__inner">
                <div className="user__info text-center">
                    <h3 className="commissioner text-[30px] font-[600] leading-[37px]">
                        My Account
                    </h3>

                    <div>
                        <input
                            type="file"
                            ref={inputRef}
                            accept="image/*"
                            onChangeCapture={onFileChangeCapture}
                            className="file-input w-full hidden    border-0 bg-white"
                            onChange={handleImageChange}
                        />
                        
                        <div
                            onClick={inputHandler}
                            className="profile relative cursor-pointer mt-[44px] mb-[80px]"
                        >
                            <img
                                className="profile_img mx-auto w-[262px] h-[262px] rounded-full"
                                src={file === null ? my_user?.image :file }
                                alt=""
                            />
                            <p className="commissioner text-[30px] text-gray-600 absolute text-white change font-[500]">
                                Change Image
                            </p>
                        </div>
                       
                        <div className="user__log flex flex-col justify-between">
                            <div className="flex w-full max-w-[400px] md:max-w-[445px] mb-[30px] justify-between text-[20px] font-[500] leading-[24px]">
                                <div className="label commissioner">Username</div>
                                <div className="label commissioner">{my_user?.username}</div>
                            </div>
                            <div className="flex justify-between items-center mb-[25px] text-[20px] leading-[24px]">
                                <label className="label commissioner p-0 text-[20px]">
                                    First Name
                                </label>
                                <input
                                    className={
                                        changingFN === true
                                            ? "enableInput"
                                            : "disableInput2"
                                    }
                                    type="text"
                                    name="first_name"
                                    onChange={(event)=>setFN(event.target.value)}
                                    value={first_name}
                                />
                                
                                <img
                                    onClick={
                                        changingFN === true 
                                            ? () => {handleSubmit(); setChangeFN(false)}
                                            : () => setChangeFN(true)
                                        }
                                    className="editIcon"    
                                    src={(changingFN === true) ? "/accept-icon.svg" : "/edit-icon.svg"}
                                    alt="Edit"
                                    />
                            </div>
                            <div className="flex justify-between items-center w-full mb-[25px] text-[20px] leading-[24px]">
                                <label className="label commissioner p-0">
                                    Last Name
                                </label>
                                <input
                                    className={
                                        changingLN === true
                                            ? "enableInput"
                                            : "disableInput2"
                                    }
                                    type="text"
                                    name="last_name"
                                    onChange={(event)=>setLN(event.target.value)}
                                    value={last_name}
                                />
                                <img
                                    onClick={
                                        changingLN === true 
                                            ? () => {handleSubmit(); setChangeLN(false)}
                                            : () => setChangeLN(true)
                                        }
                                    className="editIcon"    
                                    src={(changingLN === true) ? "/accept-icon.svg" : "/edit-icon.svg"}
                                    alt="Edit"
                                    />
                            </div>
                            <div className="flex justify-between items-center w-full mb-[25px] text-[20px] leading-[24px]">
                                <label className="label commissioner p-0">Email</label>
                                <input
                                    className={
                                        changingEmail === true
                                            ? "enableInput"
                                            : "disableInput2"
                                    }
                                    type="email"
                                    name="email"
                                    onChange={(event)=>setEmail(event.target.value)}
                                    value={email}
                                />
                                <img
                                    onClick={
                                        changingEmail === true 
                                            ? () => {handleSubmit(); setChangeEmail(false)}
                                            : () => setChangeEmail(true)
                                        }
                                    className="editIcon"    
                                    src={(changingEmail === true) ? "/accept-icon.svg" : "/edit-icon.svg"}
                                    alt="Edit"
                                    />
                            </div>                 
                        </div>
                        
                    </div>
                    <div className="flex my-[20px]  justify-center md:justify-between    items-center mb-[25px] text-[20px] leading-[24px]">
                       
                        <button
                            onClick={handleLogOut}
                            className="w-full outfit bg-[#3853A4] p-3 lg:py-[17px] lg:px-[50px] text-white text-[15px] lg:text-[20px] font-[500] rounded-[5px]"
                            >
                            Logout
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Account;
