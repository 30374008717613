

const detectTimeout = (res, navigate) =>
{
    let detected = ((res.response.status === 403) && (res.response.data.message.includes("JsonWebTokenError") || res.response.data.message.includes("TokenExpiredError")));
    if (detected) 
        {navigate("/");} //go to the login screen    
    return detected;
}
export default detectTimeout;
