import React, { useEffect, useState } from "react";
import "./Project.css";
import picture from "../../assets/upload-img.png";
import more from "../../assets/more.png";
import { Link, useNavigate } from "react-router-dom";
import CreateProject from "./CreateProject";
import axiosInstance from "../../utils/axiosInstance";
import ProjectUpload from "./ProjectUploadZip";
import detectTimeout from "../../utils/detectTimeout";
import AddUser from "../shared/AddUser";
import StartSession from "../shared/StartSession";
import ReactModal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import $ from 'jquery'; 

ReactModal.setAppElement('#root');

const Project = () => {
    const [user, setUser] = useState();
    const [projects, setProjects] = useState(0);
    const [filter, setFilter] = useState("");
    const [return_deactivated, setReturn_deactivated] = useState(false);
    const [return_deleted, setReturn_deleted] = useState(false);
    const [base64Image, setBase64Image] = useState("");
    const [show_create_dialog, setShowCreate] = useState(false);
    const [show_upload_dialog, setShowUpload] = useState(false);
    const [show_share_dialog, setShowShare] = useState(false);
    const [show_session_dialog, setShowSession] = useState(false);
    const [projectShareID, setProjectShareID] = useState("");
    const [selectedProjectId, setSelectedProjectId] = useState("");

    const toastAlert = (msg) => toast(msg, {containerId: "Project"});

    const navigate = useNavigate();
    const navigateToItemDetails = (id) => {
        navigate(`/dashboard/projectDetails/${id}`);
    };   
    const handle_err_response = (res) => {
        if (!detectTimeout(res, navigate))
            {toastAlert("ERROR: " + res.response.data.message);}
    }

    //need z-index or else it will not show on top
    const modalStyle = {
        overlay: {zIndex: 9999, "backgroundColor": "rgba(0, 0, 20, 0.8)",}, 
        content: {overflow:"hiddden", top:"50%", left:"50%", transform: "translate(-50%, -50%)", right:null, bottom:null, } //changing default of full window with 40px inset
    };

    //reload projects list
    useEffect(() => {
        handleMyprojects();
    }, [filter,return_deactivated,return_deleted]);
    
    //get own user data on load to determine permissions
    useEffect(() => {
        axiosInstance
            .post("/api/user/")
            .then((response) => setUser(response.data.user))
            .catch(handle_err_response);
    }, []);

    const handleMyprojects = () => {
        const info = {
            filter: filter,
            return_deactivated: return_deactivated,
            return_deleted: return_deleted,
        };
        axiosInstance
            .post("/api/project/my_projects", info)
            .then((response) => {
                setProjects(response.data.projects)
            })
            .catch(handle_err_response);
    };


    const handleLeave = (id) => {
        axiosInstance
            .post(`/api/project/leave/${id}`)
            .then((res) => {      
                toastAlert(res.data.message);      
                handleMyprojects();            
            })
            .catch(handle_err_response);
    };

    const handleDelete = (id) => {
        axiosInstance
            .post(`/api/project/delete/${id}`,)
            .then((res) => {
                toastAlert(res.data.message);
                handleMyprojects();        
            })
            .catch(handle_err_response);
    };

    const handleDisabled = (project) => {
        const activation = {
            activate: project.active === true ? "false" : "true",
        };
        axiosInstance
            .post(`/api/project/activate/${project.project_id}`, activation)
            .then((res) => {
                toastAlert(res.data.message);
                handleMyprojects();
            })
            .catch(handle_err_response);
    };

    const handleEditor = (projectId) => {
        axiosInstance
            .post("/api/project/start_editing/" + projectId)
            .then((res) => {
                redirectHandler("/editor/?editorID=" + res.data.editor_id + "&projectID=" + res.data.project_id);
        })
        .catch((err) =>{
            handle_err_response(err);
        });
    };

    const redirectHandler = (domain) => {
        window.open(` ${domain}`, "_blank");
    };

    return (
        <div className="bg-[#FFFBFB] p-5 lg:py-[61px] lg:px-[57px] lg:rounded-l-[50px] h-[100vh] overflow-y-scroll" id="my_projects">        
            <ToastContainer enableMultiContainer containerId={'Project'}/>          
            <ReactModal 
                isOpen={show_create_dialog}
                contentLabel="Create new project"
                style={modalStyle}
                onRequestClose={()=>setShowCreate(false)}
                shouldCloseOnOverlayClick={true}
            >                    
                <label
                    className="btn btn-sm btn-circle absolute right-2 top-2"
                    onClick={()=>{setShowCreate(false)}}
                >  ✕  </label>
                <CreateProject finalAction={()=>{setShowCreate(false); handleMyprojects();}} toastAlert={toastAlert} />
            </ReactModal>

            <ReactModal 
                isOpen={show_upload_dialog}
                contentLabel="Upload project from computer"
                style={modalStyle}
                onRequestClose={()=>setShowUpload(false)}
                shouldCloseOnOverlayClick={true}
            >                       
                <label
                    className="btn btn-sm btn-circle absolute right-2 top-2"
                    onClick={()=>{setShowUpload(false)}}
                >  ✕  </label>
                <ProjectUpload finalAction={()=>{setShowUpload(false); handleMyprojects();}} toastAlert={toastAlert} />
            </ReactModal>

            <ReactModal 
                isOpen={show_share_dialog}
                contentLabel="Share project with another user"
                style={modalStyle}
                onRequestClose={()=>setShowShare(false)}
                shouldCloseOnOverlayClick={true}
            >                       
                <label
                    className="btn btn-sm btn-circle absolute right-2 top-2"
                    onClick={()=>{setShowShare(false)}}
                >  ✕  </label>
                <AddUser projectId={selectedProjectId} finalAction={()=>setShowShare(false)} toastAlert={toastAlert} />
            </ReactModal>

            <ReactModal 
                isOpen={show_session_dialog}
                contentLabel="Start session"
                style={modalStyle}
                onRequestClose={()=>setShowSession(false)}
                shouldCloseOnOverlayClick={true}
            >                       
                <label
                    className="btn btn-sm btn-circle absolute right-2 top-2"
                    onClick={()=>{setShowSession(false)}}
                >  ✕  </label>
                <StartSession projectId={selectedProjectId} finalAction={()=>setShowSession(false)} toastAlert={toastAlert} />
            </ReactModal>

            <div className=" gap-[2px] mb-[15px] ">
                <div>
                    <input
                        type="search"
                        className="search_bg pl-16 input input-bordered rounded-[60px]"
                        style={{width:"100%"}}
                        onChange={(e) => setFilter(e.target.value)}
                    />
                </div>

            {user?.role === "administrator" && (
                <div className="flex gap-[20px] mb-[20px]">Filter: 
                    <button
                        className={`outfit outline_btn py-[8px] px-[33px] ${
                            return_deactivated && "bg-primary text-white"
                        }`}
                        type=""
                        onClick={() => setReturn_deactivated(!return_deactivated)}
                    >
                        Disabled
                    </button>
                    <button
                        className={`outfit outline_btn py-[8px] px-[33px] ${
                            return_deleted && "bg-primary text-white"
                        }`}
                        type=""
                        onClick={() => setReturn_deleted(!return_deleted)}
                    >
                        Deleted
                    </button>
                </div>
            )}
            </div>

            <div className="flex flex-wrap gap-[21px]">
                
                {/* this is alwyas the first card, it has some buttons on it */}
                <div className="card_box pt-[32px]">
                    <button className="outfit mx-[20px] my-[20px] bg-[#3853A4] p-3 text-white text-[25px] font-[500] rounded-[5px]" 
                            onClick={()=>{setShowCreate(true)}}>
                                Create project
                    </button>
                    <button className="outfit mx-[20px] my-[20px] bg-[#3853A4] p-3 text-white text-[25px] font-[500] rounded-[5px]" 
                            onClick={()=>{setShowUpload(true)}}>
                                Upload project
                    </button>
                </div>
                

                {/* this is all the other cards that load from my_projects */}
                {projects.length > 0 ? ( 
                    projects?.map((project) => (
                        <div key={project.project_id}>
                            <div className="card_box p-[7px]">
                                <div className="img_box" style={{height:"100%"}}>
                                    <div className="flex justify-end pt-[9px]">
                                        <div className="dropdown dropdown-left lg:dropdown-right">
                                            <label tabIndex={0}>
                                                <img className="pointer" src={more} alt="" style={{scale:"200%"}}/>
                                            </label>
                                            <ul
                                                tabIndex={0}
                                                className="commissioner dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52 ml-1"
                                            >
                                                <li>
                                                    <label
                                                        htmlFor="my-modal-user"
                                                        className={
                                                            user?.role === "viewer"
                                                                ? "hidden"
                                                                : "commissioner"
                                                        }
                                                        onClick={() =>{
                                                            setSelectedProjectId(project.project_id);
                                                            setShowShare(true);
                                                        }}
                                                    >
                                                        Share
                                                    </label>
                                                </li>

                                                <li
                                                    className={
                                                        user?.role === "administrator" ? "hidden" : "block"
                                                    }
                                                >
                                                    <button
                                                        className="commissioner"
                                                        onClick={() => handleLeave(project.project_id)}
                                                    >
                                                        Leave project
                                                    </button>
                                                </li>
                                                {/*<li>
                                                    <label                                                       
                                                        className={
                                                            user?.role === "viewer"
                                                                ? "hidden"
                                                                : "commissioner"
                                                        }
                                                        onClick={() =>{
                                                            setSelectedProjectId(project.project_id);
                                                            setShowSession(true);
                                                        }}
                                                    >
                                                        Start session
                                                    </label>
                                                </li>
                                                 <li>
                                                    <Link
                                                        className="commissioner"
                                                        to={`/dashboard/projectCopy/${project.project_id}`}
                                                    >
                                                        copy project
                                                    </Link>
                                                </li> */}
                                                <li>
                                                    <p  onClick={() => handleEditor(project.project_id)}
                                                        className={
                                                            user?.role === "viewer"
                                                                ? "hidden"
                                                                : "commissioner"
                                                        }>
                                                        {" "}Edit project
                                                    </p>
                                                </li>
                                                <li>
                                                    <Link
                                                        className="commissioner"
                                                        to={`/dashboard/project-log/${project.project_id}`}
                                                    >
                                                        Log
                                                    </Link>
                                                </li>
                                                {user?.role === "administrator" && (
                                                    <>
                                                        <li onClick={() => handleDisabled(project)}>
                                                            <a className="commissioner">
                                                                {project?.active === true
                                                                    ? "Disable project"
                                                                    : "Activate project"}{" "}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                onClick={() => handleDelete(project.project_id)}
                                                                className="commissioner"
                                                            >
                                                                Delete project
                                                            </a>
                                                        </li>
                                                    </>
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                    <div>
                                        <div
                                            className="cursor-pointer"
                                            onClick={() => navigateToItemDetails(project.project_id)}
                                        >
                                            <img
                                                className="h-[150px] w-full    mx-auto"
                                                src={project.image}
                                                alt=""
                                            />
                                            <p className="commissioner text-[16px] font-[500] mt-2 mb-[8px] text-center">
                                                {project.name}
                                            </p>
                                        </div>
                                        <p className="commissioner text-[14px] font-[400] text-center pb-2">
                                            {project.description}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                ) : projects.length === 0 ? (
                    <div>No Project Found</div>
                ) : (
                    <div>Loading...</div>
                )}
               
            </div>
        </div>
    );
};

export default Project;
