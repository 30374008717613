import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../utils/axiosInstance";
import detectTimeout from "../../utils/detectTimeout";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const NewUser = ({ toastAlert, finalAction}) => {
    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");  
    const [first_name, setFN] = useState("");
    const [last_name, setLN] = useState("");
    const [email, setEmail] = useState("");
    const [admin, setAdmin] = useState("analyst");

    const my_toastAlert = (msg) => toast(msg, {containerId: "NewUser"}); //alerts on overlay instead of behind it

    const handle_err_response = (res) => {
        if (!detectTimeout(res, navigate)) { 
            my_toastAlert(res.response?.data.message);
        }
    }

    const handleAccept = () => {
        const data = {
            username,
            password,
            first_name,
            last_name,
            email,
            role:admin
        };

        axiosInstance
            .post(`/api/user/signup/`, data)
            .then((res) => {
                toastAlert(res.data.message);
                finalAction(); //will hide this dialog
            })
            .catch(handle_err_response);
    };

    return (
        <div className="bg-white lg:rounded-l-[50px] project__copy w-full h-full flex justify-center items-center">
            <ToastContainer enableMultiContainer containerId={'NewUser'}/>
            <div className="copy__inner    border-[1px] rounded-[8px] bordered-[#F8FAFF] shadow-black px-[28px] py-[20px] flex flex-col items-center w-[593px]">
            <div className="mb-[33px] w-full flow-root">
                    <label className="flex outfit text-[20px] font-[300] mb-[10px] block py-[10px] px-[1em]">
                        Username
                    </label>
                    <input
                        type="text"
                        className="flex input input-bordered w-[300px] h-[56px]"
                        name="username"
                        onChange={(event)=>setUsername(event.target.value)}
                        value={username}
                    />

                    <label className="flex outfit text-[20px] font-[300] mb-[10px] block py-[10px] px-[1em]">
                        Password
                    </label>
                    <input
                        type="text"
                        className="flex input input-bordered w-[300px] h-[56px]"
                        name="username"
                        onChange={(event)=>setPassword(event.target.value)}
                        value={password}
                    />

                    <label className="outfit text-[20px] font-[300] mb-[10px] block py-[10px] px-[1em]">
                        First Name
                    </label>
                    <input
                        type="text"
                        className="input input-bordered w-[300px] h-[56px]"
                        name="username"
                        onChange={(event)=>setFN(event.target.value)}
                        value={first_name}
                    />

                    <label className="outfit text-[20px] font-[300] mb-[10px] block py-[10px] px-[1em]">
                        Last Names
                    </label>
                    <input
                        type="text"
                        className="input input-bordered w-[300px] h-[56px]"
                        name="username"
                        onChange={(event)=>setLN(event.target.value)}
                        value={last_name}
                    />

                    <label className="outfit text-[20px] font-[300] mb-[10px] block py-[10px] px-[1em]">
                        Email
                    </label>
                    <input
                        type="text"
                        className="input input-bordered w-[300px] h-[56px]"
                        name="username"
                        onChange={(event)=>setEmail(event.target.value)}
                        value={email}
                    />
                        <label className="outfit text-[20px] font-[300] mb-[10px] block py-[10px] px-[1em]">
                        Admin
                    </label>

                    <select onChange={event=>setAdmin(event.target.value)} defaultValue={"analyst"}>
                        <option value="administrator">true</option>
                        <option value="analyst">false</option>
                    </select>
                </div>
                <button
                    onClick={handleAccept}
                    className="mt-4 commissioner min-w-[121px] bg-[#F1F6FF] text-[20px] leading-[38px font-[500] rounded-[8px] p-[8px] mb-[10px]"
                >
                    Accept
                </button>
            </div>
        </div>
    );
};

export default NewUser;
