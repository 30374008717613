import React, { useContext, useEffect, useState } from "react";
import "./manage.css";
import detectTimeout from "../../utils/detectTimeout";
import TableBtn from "../../utils/TableBtn";
import NewUser from "../shared/NewUser";
import { UserContext } from "../../context/AuthProvider";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../../utils/axiosInstance";
import { ToastContainer, toast } from 'react-toastify';
import ReactModal from 'react-modal';
import 'react-toastify/dist/ReactToastify.css';

const Manage = () => {
    const navigate = useNavigate();
    const { my_user, setMyUser } = useContext(UserContext);
    const [filter, setFilter] = useState("");
    const [return_deleted, setReturn_deleted] = useState(false);
    const [ userList, setUserList ] = useState([]);
    const [ show_add_user, setShowAddUser ] = useState(false);

    const toastAlert = (msg) => toast(msg, {containerId: "Users"});

    //need z-index or else it will not show on top
    const modalStyle = {
        overlay: {zIndex: 9999, "backgroundColor": "rgba(0, 0, 20, 0.8)",}, 
        content: {overflow:"hiddden", top:"50%", left:"50%", transform: "translate(-50%, -50%)", right:null, bottom:null, } //changing default of full window with 40px inset
    };
    
    const handle_err_response = (res) => {
        if (!detectTimeout(res, navigate)) 
            {toastAlert("ERROR: " + res.response.data.message);}
    }

    const buttonStyle = {width:"100%", height:"100%"};
    const tableStyle = {padding:"10px"};

    const getUsers = () => {
        const info = {
            filter: filter,
            return_deleted: return_deleted,
        };
        axiosInstance
            .post("/api/user/list", info)
            .then((response) => setUserList(response.data.users))
            .catch(handle_err_response);
    }

    useEffect(() => {
        getUsers()
    }, [filter, return_deleted]);

    const handleDelete = (user) => {
        axiosInstance
            .post(`/api/user/delete/${user.user_id}`, user)
            .then((res) =>{
                toastAlert("User was deleted");
                getUsers();
            })
            .catch(handle_err_response);
    };

    const handleActive = (user) => {
        const status = {
            status: user?.status === "active" ? "suspended" : "active",
        };
        axiosInstance
            .post(`/api/user/change_status/${user.user_id}`, status)
            .then((res) =>{
                toastAlert("User activation changed");
                getUsers();
            })
            .catch(handle_err_response);
    };

    return (
        <div className="Mange__User bg-[#FFFBFB] lg:rounded-l-[50px] h-full lg:px-[57px] lg:py[61px] p-4 overflow-x-auto">
            <ToastContainer enableMultiContainer containerId={'Users'}/>          
            <ReactModal 
                isOpen={show_add_user}
                contentLabel="Sign Up A User"
                style={modalStyle}
                onRequestClose={()=>setShowAddUser(false)}
                shouldCloseOnOverlayClick={true}
            >                       
                <label
                    className="btn btn-sm btn-circle absolute right-2 top-2"
                    onClick={()=>{setShowAddUser(false)}}
                >  ✕  </label>
                <NewUser finalAction={()=>{setShowAddUser(false);getUsers();}} toastAlert={toastAlert} />
            </ReactModal>


            <div className="top__btn flex items-center mb-[82px]">
                <input
                    type="search"
                    className="search_bg pl-16 input input-bordered w-1/2 rounded-[60px] h-[58px] "
                    onChange={(e) => setFilter(e.target.value)}
                />
                <button
                    className={`gray__btn text-[20px] font-[500] leading-[30px] ml-[20px] px-[38px] py-[18px] rounded-[5px] bg-[#f0f4ff] bordered-[1px] ${
                        return_deleted && "bg-primary text-white"
                    }`}
                    onClick={() => setReturn_deleted(!return_deleted)}
                >
                    Show Deleted
                </button>
            </div>

            <button
                    className={`gray__btn text-[16px] font-[500] leading-[30px] ml-[20px] px-[10px] py-[10px] my-[10px] rounded-[5px] bg-[#f0ccff] bordered-[1px] ${
                        return_deleted && "bg-primary text-white"
                    }`}
                    onClick={() => setShowAddUser(true)}
                >
                    Add User
            </button>
            <table style={{ width: "100%" }}>
                <thead>
                    <tr className="commissioner">
                        <th className="text-left" style={tableStyle}>Image</th>
                        <th className="text-left" style={tableStyle}>Username</th>
                        <th className="text-left" style={tableStyle}>First Name</th>
                        <th className="text-left" style={tableStyle}>Last Name</th>
                        <th className="text-left" style={tableStyle}>Account Type</th>
                        <th className="text-left" style={tableStyle}>Status</th>
                        <th className="text-center" style={tableStyle}>Accounts/Edit</th>
                    </tr>
                </thead>
                <tbody>
                    {userList.map((i, index) => (
                        <tr key={index}>
                            <td style={tableStyle}>
                                <img width={60} src={i.image} alt="User Image"/>
                            </td>
                            <td style={tableStyle}>{i.username}</td>
                            <td style={tableStyle}>{i.first_name}</td>
                            <td style={tableStyle}>{i.last_name}</td>
                            <td style={tableStyle}>{i.role}</td>
                            <td style={tableStyle}>{i.status}</td>

                            <td style={tableStyle}>
                                <div className="flex justify-between">
                                    <TableBtn>
                                        {my_user?.role === "administrator" ? (
                                            <button 
                                                style={{...buttonStyle, ... {width:"6em"}}}
                                                onClick={() => handleActive(i)}
                                                >
                                                {i.status=="active" ? "Deactivate" : "Activate"}
                                            </button> ) 
                                            : ("")
                                        }
                                    </TableBtn>

                                    <TableBtn>
                                        {my_user?.role === "administrator" ? (
                                            <button 
                                            style={buttonStyle}
                                            onClick={() => handleDelete(i)} type=""
                                                >
                                                Delete
                                            </button>
                                        ) : (
                                            ""
                                        )}
                                    </TableBtn>

                                    <Link to={`/dashboard/edit-user/${i.user_id}`}>
                                        <TableBtn>
                                            <button style={buttonStyle}>
                                               Edit
                                            </button>
                                        </TableBtn> 
                                    </Link>
                                    <Link to={`/dashboard/user-log/${i.user_id}`}>
                                        <TableBtn> <button style={buttonStyle}>
                                               Log
                                            </button>
                                        </TableBtn>
                                    </Link>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Manage;
