import axiosInstance from "../../utils/axiosInstance";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import detectTimeout from "../../utils/detectTimeout";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CreateProject = ({toastAlert, finalAction}) => {
    const navigate = useNavigate();
    const [file, setFile] = useState(null);
    const [base64Image, setBase64Image] = useState("");
    const inputRef = useRef();

    // image upload start
    const onFileChangeCapture = (e) => {
        e.preventDefault();
        setFile(URL.createObjectURL(e.target.files[0]));
    };  

    //this one displays on top of the overlay
    const my_toastAlert = (msg) => toast(msg, {containerId:'NewProject'});

    const inputHandler = () => {
        inputRef.current.click();
    };
    // image upload end
    const handleImageChange = (event) => {
        event.preventDefault();
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            setBase64Image(reader.result);
        };

        reader.readAsDataURL(file);
    };

    const handle_err_response = (res) => {
        if (!detectTimeout(res, navigate)) 
            {my_toastAlert("ERROR: " + res.response.data.message);}
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const project_name = e.target.project_name.value;
        const project_description = e.target.project_description.value;

        const info = {
            project_name,
            file_data: "data:text/plain;base64,",
            project_path_to_execute: "project/project.360",
            project_description,
            project_image: base64Image,
        };


        axiosInstance
            .post("/api/project/create", info)
            .then((res) => {
                    let projectId = res.data.project_id;
                    axiosInstance
                        .post("/api/project/start_editing/" + projectId)
                        .then((res) => {
                            finalAction();
                            redirectHandler("/editor/?editorID=" + res.data.editor_id + "&projectID=" + res.data.project_id);
                    })
                    .catch(handle_err_response);
                })
            .catch(handle_err_response);
        
    };

    const redirectHandler = (domain) => {
        window.open(` ${domain}`, "_blank");
    };
    return (
        <div>
            <ToastContainer enableMultiContainer containerId={'NewProject'}/>
            <h1 className="text-center text-3xl font-bold mb-5">New Project</h1>
            <form onSubmit={handleSubmit}>
                <div className="mb-[33px]">
                    <label className="outfit text-[20px] font-[300] mb-[10px] block">
                        Project Name <sup className="text-[#C9312E]">*</sup>
                    </label>
                    <input
                        type="text"
                        className="input input-bordered w-full h-[56px]"
                        name="project_name"
                    />
                </div>
                <div className="mb-[33px]">
                    <label className="outfit text-[20px] font-[300] mb-[10px] block">
                        Project Description <sup className="text-[#C9312E]">*</sup>
                    </label>
                    <textarea
                        className="input input-bordered w-full"
                        name="project_description"
                    ></textarea>
                </div>
                <div className="copy__img border-[1px] overflow-hidden w-3/4 mx-auto bordered rounded-[8px] mb-4">
                    <input
                        type="file"
                        ref={inputRef}
                        accept="image/*"
                        onChangeCapture={onFileChangeCapture}
                        onChange={handleImageChange}
                        className="file-input w-full hidden    border-0 bg-white"
                    />
                    {file === null ? (
                        <div
                            onClick={inputHandler}
                            className="profile relative cursor-pointer "
                        >
                            <img
                                className="relative z-[99] h-[192px] w-full"
                                src="https://lppm.upnjatim.ac.id/assets/img/nophoto.png"
                                alt=""
                            />
                            <button type="button" className="underline commissioner w-full bg-[#F8FAFF] text-[18px] font-[500] text-center py-[15px] mt-[-10px] z-[9]">
                                Pick new image
                            </button>
                        </div>
                    ) : (
                        <div
                            onClick={inputHandler}
                            className="profile relative cursor-pointer "
                        >
                            <img
                                className="relative z-[99] w-full h-[192px] "
                                src={file}
                                alt=""
                            />
                            <button className="underline commissioner w-full bg-[#F8FAFF] text-[18px] font-[500] text-center py-[15px] mt-[-10px] z-[9]">
                                Pick new image
                            </button>
                        </div>
                    )}
                </div>
                <div className="flex justify-center gap-[30px] mb-[30px]">
                    <button
                        type="submit"
                        className="outfit bg-[#3853A4] p-3 lg:py-[17px] lg:px-[50px] text-white text-[15px] lg:text-[20px] font-[500] rounded-[5px]"
                    >
                        Create
                    </button>
                </div>
            </form>
        </div>
    );
};

export default CreateProject;
