import axiosInstance from "../../utils/axiosInstance";
import React, { useEffect, useState } from "react";
import detectTimeout from "../../utils/detectTimeout";
import {useNavigate } from "react-router-dom";
import "./manage.css";

const NodeInfo = () => {
    const [node, setNode] = useState([]);
    const navigate = useNavigate();

    const handle_err_response = (res) => {
       detectTimeout(res, navigate)
    }

    useEffect(() => {
        axiosInstance
            .post("/api/node", )
            .then((res) => setNode(res.data.nodes))
            .catch(handle_err_response);
    }, []);
    
    return (
        <div className="node__info bg-[#FFFBFB] lg:rounded-l-[50px] h-full overflow-x-auto lg:py-[20px] lg:pt-[20px] lg:px-[20px] p-4">
            <h1 className="text-3xl font-[600] mb-8">Node Information</h1>

            <table  >
                <thead>
                    <tr className="bg-gray-300 h-[60px]">
                        <th  className="px-[10px]">Host name</th>
                        <th className="px-[10px]">Address</th>
                        <th className="px-[10px]">Memory mibytes</th>
                        <th className="px-[10px]">No of CPU</th>
                        <th className="px-[10px]">Creation Time</th>
                        <th className="px-[10px]">Status</th>
                    </tr>
                </thead>
                <tbody>
                {node?.map((i, index) => (
                    <tr key={"node-" + index}>
                        <td> {i.hostname}</td>
                        <td> {i.address}</td>
                        <td> {Math.round(i.memory_mibytes)}</td>
                        <td> {i.num_cpu}</td>
                        <td> {new Date(i.creation_time).toLocaleDateString("en-ca", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric'})}</td>
                        <td>{i.status}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default NodeInfo;
