import axiosInstance from "../../utils/axiosInstance";
import React, { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../../context/AuthProvider";
import { useNavigate, useParams } from "react-router-dom";
import "./account.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import detectTimeout from "../../utils/detectTimeout";

const EditUser = () => {
    const { my_user, setMyUser } = useContext(UserContext);
    
    const [user, setUser] = useState({});
    
    const [password, setPW] = useState("");
    const [first_name, setFN] = useState("");
    const [last_name, setLN] = useState("");
    const [email, setEmail] = useState("");
    const [changingFN, setChangeFN] = useState(false);
    const [changingLN, setChangeLN] = useState(false);
    const [changingEmail, setChangeEmail] = useState(false);
    const [changingPW, setChangePW ]= useState(false);

    const [file, setFile] = useState(null);
    const [base64Image, setBase64Image] = useState("");

    const toastAlert = (msg) => {toast(msg, {containerId:"EditUser"})};

    const navigate = useNavigate();

    const { id } = useParams();

    const handle_err_response = (res) => {
        if (!detectTimeout(res, navigate))
           {toastAlert(res.response.data.message);
            stopEditing();}
    }

    

    const requestUser = () =>
    {
        axiosInstance
            .post( "/api/user/", {user_id:id})
            .then((response) => setUser(response.data.user))
            .catch(handle_err_response);
    }

    const updateUser= () =>
    {
        if (user)
        {   
            //frustratingly we can maybe see a null in one of these fields and then react complains
            (user.first_name) ? setFN( user?.first_name):  setFN("");
            (user.last_name)  ? setLN(user?.last_name ):  setLN("");
            (user.email)      ? setEmail( user?.email):  setEmail("");
            setPW("");
        }
    }


    useEffect(() => {
        if(base64Image) handleSubmit();
     }, [base64Image]);

    useEffect(() => {
        requestUser();
     }, []);

    useEffect(() => {
       updateUser();
    }, [user]);

    const inputRef = useRef();
    const onFileChangeCapture = (e) => {
        setFile(URL.createObjectURL(e.target.files[0]));
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            setBase64Image(reader.result);
        };

        reader.readAsDataURL(file);
    };

    const inputHandler = () => {
        inputRef.current.click();
    };

    const stopEditing = () =>
    {
        setChangeEmail(false);
        setChangeFN(false);
        setChangeLN(false);  
        setChangePW(false);    
        setPW("");
    }


    const handleSubmit = () => {
        const data = {
            first_name,
            last_name,
            email: email=="no email"? "" : email,
            password,
            user_image: base64Image ? base64Image : undefined,
            user_id: id,
        };

        axiosInstance
            .post("/api/user/edit", data)
            .then((res) => {
                toastAlert(res.data.message);
                setUser(res.data.user);   
                stopEditing();
            })
            .catch(handle_err_response);
    };

    return (
        <div className="user__set bg-white lg:rounded-l-[50px]    p-[40px] md:px-[100px] md:py-[40px]">
            <ToastContainer enableMultiContainer containerId={'EditUser'}/>
            <div className="user__inner">
                <div className="user__info text-center">
                    <h3 className="commissioner text-[30px] font-[600] leading-[37px]">
                        Edit user
                    </h3>

                    <div>
                        <input
                            type="file"
                            ref={inputRef}
                            accept="image/*"
                            onChangeCapture={onFileChangeCapture}
                            className="file-input w-full hidden    border-0 bg-white"
                            onChange={handleImageChange}
                        />
                        <div
                            onClick={inputHandler}
                            className="profile relative cursor-pointer mt-[44px] mb-[80px]"
                        >
                            <img
                                className="profile_img mx-auto w-[262px] h-[262px] rounded-full"
                                src={file === null ? user?.image : file}
                                alt=""
                            />
                            <p className="commissioner absolute text-[30px] text-gray-600 change font-[600]">
                                Change Image
                            </p>
                        </div>
                   
                        <div className="flex w-full max-w-[400px] md:max-w-[445px] mb-[30px] justify-between text-[20px] font-[500] leading-[24px]">
                            <div className="label commissioner">Username</div>
                            <div className="label commissioner">{user?.username}</div>
                        </div>
                        <div className="flex justify-between items-center mb-[25px] text-[20px] leading-[24px]">
                            <label className="label commissioner p-0 text-[20px]">
                                Password
                            </label>
                            <input
                                className={
                                    changingPW === true
                                        ? "enableInput"
                                        : "disableInput2"
                                }
                                type="text"
                                name="first_name"
                                onChange={(event)=>setPW(event.target.value)}
                                value={password}
                                placeholder="***********"
                            />
                            <p className="outfit text-[12px] font-[400] text-[#767676]">
                                Minimum 8 characters
                            </p>
                            
                            <img
                                onClick={
                                    changingPW === true 
                                        ? () => {handleSubmit(); setChangePW(false)}
                                        : () => setChangePW(true)
                                    }
                                className="editIcon"    
                                src={(changingPW === true) ? "/accept-icon.svg" : "/edit-icon.svg"}
                                alt="Edit"
                                />
                        </div>
                        <div className="flex justify-between items-center mb-[25px] text-[20px] leading-[24px]">
                            <label className="label commissioner p-0 text-[20px]">
                                First Name
                            </label>
                            <input
                                className={
                                    changingFN === true
                                        ? "enableInput"
                                        : "disableInput2"
                                }
                                type="text"
                                name="first_name"
                                onChange={(event)=>setFN(event.target.value)}
                                value={first_name}
                            />
                            
                            <img
                                onClick={
                                    changingFN === true 
                                        ? () => {handleSubmit(); setChangeFN(false)}
                                        : () => setChangeFN(true)
                                    }
                                className="editIcon"    
                                src={(changingFN === true) ? "/accept-icon.svg" : "/edit-icon.svg"}
                                alt="Edit"
                                />
                        </div>
                        <div className="flex justify-between items-center w-full mb-[25px] text-[20px] leading-[24px]">
                            <label className="label commissioner p-0">
                                Last Name
                            </label>
                            <input
                                className={
                                    changingLN === true
                                        ? "enableInput"
                                        : "disableInput2"
                                }
                                type="text"
                                name="last_name"
                                onChange={(event)=>setLN(event.target.value)}
                                value={last_name}
                            />
                            <img
                                onClick={
                                    changingLN === true 
                                        ? () => {handleSubmit(); setChangeLN(false)}
                                        : () => setChangeLN(true)
                                    }
                                className="editIcon"    
                                src={(changingLN === true) ? "/accept-icon.svg" : "/edit-icon.svg"}
                                alt="Edit"
                                />
                        </div>
                        <div className="flex justify-between items-center w-full mb-[25px] text-[20px] leading-[24px]">
                            <label className="label commissioner p-0">Email</label>
                            <input
                                className={
                                    changingEmail === true
                                        ? "enableInput"
                                        : "disableInput2"
                                }
                                type="email"
                                name="email"
                                onChange={(event)=>setEmail(event.target.value)}
                                value={email}
                            />
                            <img
                                onClick={
                                    changingEmail === true 
                                        ? () => {handleSubmit(); setChangeEmail(false)}
                                        : () => setChangeEmail(true)
                                    }
                                className="editIcon"    
                                src={(changingEmail === true) ? "/accept-icon.svg" : "/edit-icon.svg"}
                                alt="Edit"
                                />
                        </div>         
                        {/*
                        <div className="mb-[42px] flex items-center gap-3">
                            <label className="outfit text-[20px] font-[300] block">
                                Role
                            </label>
                            <select name="role">
                                <option value="analyst">Analyst</option>
                            </select>
                        </div>*/}

                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditUser;
