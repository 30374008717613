import React, { useContext, useEffect, useRef, useState } from "react";
import axiosInstance from "../../utils/axiosInstance";
import { Link, useNavigate, useParams } from "react-router-dom";
import ProjectCopy from "../shared/ProjectCopy";
import StartSession from "../shared/StartSession";
import detectTimeout from "../../utils/detectTimeout";
import AddUser from "../shared/AddUser";
import SmbMount from "../shared/SmbMount";
import { UserContext } from "../../context/AuthProvider";
import EditProject from "./EditProject";
import ReactModal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

ReactModal.setAppElement('#root');

const ProjectDetails = () => {
    const { my_user, setMyUser } = useContext(UserContext);
    const [project, setProject] = useState();
    const [projectLog, setProjectLog] = useState([]);
    const { id } = useParams();
    const navigate = useNavigate();
    const [show_share_dialog, setShowShare] = useState(false);
    const [show_session_dialog, setShowSession] = useState(false);
    const [show_copy_dialog, setShowCopy] = useState(false);
    const [show_info_dialog, setShowInfo] = useState(false);
    const [show_smb_dialog, setShowSmb] = useState(false);
    const [divStyle, setErrorStyle] = useState({display:'none'});
    
    const [disable_btn_style, setDBStyle] = useState({background:"crimson"});

    const toastAlert = (msg) => toast(msg, {containerId: "ProjectDetails"});
    const toastAlert_dash = (msg) => toast(msg, {containerId: "Project"}); //for leaving a project we have to send an alert to the dashboard
    
    const handle_err_response = (res) => {
        if (!detectTimeout(res, navigate)) 
            {toastAlert("ERROR: " + res.response.data.message);}
    }

    //need z-index > 10 ish , or else it will not show on top, no clue where the increased z-index of the app is coming from
    const modalStyle = {
        overlay: {zIndex: 10, "backgroundColor": "rgba(0, 0, 20, 0.8)",}, 
        content: {overflow:"hiddden", top:"50%", left:"50%", transform: "translate(-50%, -50%)", right:null, bottom:null} //changing default of full window with 40px inset
    };

    const getProjectDetails = () => {
        axiosInstance
            .post(`/api/project/details/${id}`)
            .then((response) =>
            {
                setProject(response.data.project)
            })
            .catch((err) =>
            {
                handle_err_response(err); //check for token expiry
                setProject();               //set project to empty
                setErrorStyle({display:""}) //remove hidden style from error message
            })
        //parallel request for logs:
        axiosInstance
            .post(`/api/project/logs/${id}`)
            .then((response) => setProjectLog(response.data.log_strings))
            .catch(handle_err_response);
    };

    useEffect(() => {
        if (project)
        {
            if (project.active === true){
                setDBStyle({background:"crimson"})
            } else {
                setDBStyle({background:"lightgreen"})
            }
        }
    }, [project]);
    
    useEffect(() => {
        getProjectDetails();
    }, [id]);

    // change image part
    const [files, setFile] = useState(null);
    const inputRef = useRef();
    
    const [base64Image, setBase64Image] = useState("");
    const onFileChangeCapture = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            setBase64Image(reader.result);
        };

        reader.readAsDataURL(file);
        setFile(URL.createObjectURL(e.target.files[0]));
    };

    const dateFormat = (date) => {
        const formatDate = new Date(date);
        return formatDate.toLocaleDateString("en-ca", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'})
        
    };

    const save = (filename, data) => {
        if(window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(data, filename);
        }
        else{
            const elem = window.document.createElement('a');
            elem.href = data;
            elem.download = filename;        
            document.body.appendChild(elem);
            elem.click();        
            document.body.removeChild(elem);
        }
    }

    
    const handleLeave = (id) => {
        axiosInstance
            .post(`/api/project/leave/${id}`)
            .then((res) => {            
                navigate(`/dashboard/project`)
                setTimeout(()=> { toastAlert_dash(res.data.message);}, 100);
            })
            .catch(handle_err_response);
    };

    const handleProjectName = (projectId) => {
        if (projectId === id) {
            return project.name;
        }
    };

    const handleDeleteSession = (id) => {
        axiosInstance
            .post(`/api/session/delete/${id}`)
            .then((res) => {
                toastAlert(res.data.message);
                getProjectDetails();
            })
            .catch(handle_err_response);
    };

    const handleUserRemove = (projectId, userId) => {
        axiosInstance
            .post(`/api/project/remove_user/${projectId}`, {
                user_id: userId,
            })
            .then((res) => {
                toastAlert(res.data.message);
                getProjectDetails();
            })
            .catch(handle_err_response);
    };

    const handleDelete = (id) => {
        axiosInstance
            .post(`/api/project/delete/${id}`)

            .then((res) => {
                console.log(res);
                if (res.data.message === "project successfully deleted.") {
                    navigate("/dashboard/project");
                    setTimeout(()=> { toastAlert_dash(res.data.message);}, 100);
                } else {
                    toastAlert(res.data.message);
                }
            })            
            .catch(handle_err_response);
    };

    const handleDeleteSmb = (mount) => {
        axiosInstance
            .post(`/api/project/remove_mount/${id}`, {mount})
            .then((res) => {
                toastAlert(res.data.message);
                getProjectDetails();
            })            
            .catch(handle_err_response);
    };

    const handleDisabled = (project) => {
        const activation = {
            activate: project.active === true ? "false" : "true",
        };
        axiosInstance
            .post(`/api/project/activate/${project.project_id}`, activation)
            .then((res) => {
                toastAlert(res.data.message);
                getProjectDetails();
            })           
            .catch(handle_err_response);
    };

    const handleDownloadProject = (something) =>
    {
        console.log();
        axiosInstance
            .post(`/api/project/dnd_download/${id}`)
            .then(res => {
                const zipString = res.data.project.zip_file.substring(res.data.project.zip_file.indexOf(",") +1);
                save( project ? project.name + "-cloud-export.zip" :"project.zip", "data:application/octet-stream;base64," + zipString);
            })
            .catch(handle_err_response)
    }

    const handleChangeUserRole = (event, user) => {
        let role = event.target.value;
        let user_id = user.user_id;
        axiosInstance
            .post(`/api/project/change_user_role/${project.project_id}`, {role, user_id})
            .then((res) => {
                toastAlert(res.data.message);
                getProjectDetails();
            })           
            .catch(handle_err_response);
    };

    const handleEditor = (projectId) => {
        axiosInstance
            .post("/api/project/start_editing/" + projectId)
            .then((res) => {
                redirectHandler("/editor/?editorID=" + res.data.editor_id + "&projectID=" + res.data.project_id);
            })
            .catch(handle_err_response);
    };

    const redirectHandler = (domain) => {
        window.open(` ${domain}`, "_blank");
    };

    return ( project?
        <div className="bg-[#FFFBFB] lg:py[61px] lg:px-[57px] lg:rounded-[50px] p-4">
            <ToastContainer enableMultiContainer containerId={'ProjectDetails'}/>
            <ReactModal 
                isOpen={show_share_dialog}
                contentLabel="Share project with another user"
                style={modalStyle}
                onRequestClose={()=>setShowShare(false)}
                shouldCloseOnOverlayClick={true}
            >                       
                <label
                    className="btn btn-sm btn-circle absolute right-2 top-2"
                    onClick={()=>{setShowShare(false)}}
                >  ✕  </label>
                <AddUser projectId={id} finalAction={()=>{setShowShare(false);getProjectDetails();}} toastAlert={toastAlert} />
            </ReactModal>

            <ReactModal 
                isOpen={show_session_dialog}
                contentLabel="Start session"
                style={modalStyle}
                onRequestClose={()=>setShowSession(false)}
                shouldCloseOnOverlayClick={true}
            >                       
                <label
                    className="btn btn-sm btn-circle absolute right-2 top-2"
                    onClick={()=>{setShowSession(false)}}
                >  ✕  </label>
                <StartSession projectId={id} finalAction={()=>{setShowSession(false);getProjectDetails();}} toastAlert={toastAlert} />
            </ReactModal>

            <ReactModal 
                isOpen={show_copy_dialog}
                contentLabel="Start session"
                style={modalStyle}
                onRequestClose={()=>setShowCopy(false)}
                shouldCloseOnOverlayClick={true}
            >                       
                <label
                    className="btn btn-sm btn-circle absolute right-2 top-2"
                    onClick={()=>{setShowCopy(false)}}
                >  ✕  </label>
                <ProjectCopy projectId={id} finalAction={()=>{setShowCopy(false);}} projectDetails={project} toastAlert={toastAlert} />
            </ReactModal>

            <ReactModal 
                isOpen={show_smb_dialog}
                contentLabel="Add SMB Mountpoint"
                style={modalStyle}
                onRequestClose={()=>setShowSmb(false)}
                shouldCloseOnOverlayClick={true}
            >                       
                <label
                    className="btn btn-sm btn-circle absolute right-2 top-2"
                    onClick={()=>{setShowSmb(false)}}
                >  ✕  </label>
                <SmbMount projectId={id} finalAction={()=>{setShowSmb(false);getProjectDetails();}} projectDetails={project} toastAlert={toastAlert} />
            </ReactModal>

            <ReactModal 
                isOpen={show_info_dialog}
                contentLabel="Edit info"
                style={modalStyle}
                onRequestClose={()=>setShowInfo(false)}
                shouldCloseOnOverlayClick={true}
            >                       
                <label
                    className="btn btn-sm btn-circle absolute right-2 top-2"
                    onClick={()=>{setShowInfo(false)}}
                >  ✕  </label>
                 <EditProject projectId={id} finalAction={()=>{setShowInfo(false);getProjectDetails();}} toastAlert={toastAlert} />
            </ReactModal>

            <div className="max-w-[1091px]">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-[20px] mb-[40px]">
                    <div className="flow-root"  >
                        <p
                            className="flex items-center justify-between px-4 text-[32px] commissioner w-full mb-[5px] py-[0px] font-[500]"
                            type=""
                            >
                            {project?.name}
                        </p>

                        <p
                            className="flex items-center justify-between px-4 commissioner w-full mb-[20px] py-[0px] font-[500]"
                            type=""
                            >
                            {project?.description
                                ? project?.description
                                : "Project Description"}{" "}
                        </p>
                        <div className="flow-root gap-[15px] mb-[20px]">
                            {my_user?.role === "administrator" && (              
                                <button
                                    onClick={() => handleDisabled(project)}
                                    className="mx-[1em] my-[5px] py-[12px] text-[12px] w-[10em] font-[500] rounded-[5px]"
                                    style={disable_btn_style}
                                    >
                                    {project?.active === true
                                        ? "Disable project"
                                        : "Activate project"}
                                </button>
                             )}

                            {(my_user?.role === "administrator") &&  (
                                <button
                                    onClick={() => handleDelete(project.project_id)}
                                    className="bg-[#3853A4] my-[5px] mx-[1em] py-[12px] w-[10em] text-[12px] font-[500] rounded-[5px]"
                                    style={{background:"crimson"}}
                                    >  
                                    Delete project
                                </button>
                          
                            )}

                            {(my_user?.role !== "administrator") &&  (
                                <button
                                    onClick={() => handleLeave(project.project_id)}
                                    className="bg-[#3853A4] mx-[1em] py-[12px] my-[5px] text-white w-[10em] text-[16px] font-[500] rounded-[5px] text-center"
                                    style={{background:"goldenrod"}}
                                    >
                                    Leave Project
                                </button>
                            )}

                            {project?.your_role != "viewer" && (     
                                <button
                                    onClick={() => handleEditor(project.project_id)}
                                    className={
                                        my_user?.role === "viewer"
                                            ? "hidden"
                                            : "bg-[#3853A4] mx-[1em] py-[12px] my-[5px] text-white w-[10em] text-[16px] font-[500] rounded-[5px] text-center"
                                    }
                                    >
                                    Open in Editor
                                </button>
                            )}

                            <button 
                                className="outfit mx-[1em] my-[5px] py-[12px] w-[10em] bg-[#3853A4] text-white text-[16px] font-[500] rounded-[5px]" 
                                onClick={()=>{setShowCopy(true)}}
                                >
                                Copy project
                            </button>

                            {project?.your_role != "viewer" && ( 
                                <button 
                                    className="outfit mx-[1em] my-[5px] py-[12px] w-[10em] bg-[#3853A4] text-white text-[16px] font-[500] rounded-[5px]" 
                                    onClick={()=>{setShowInfo(true)}}
                                    >
                                    Edit info
                                </button>
                            )}

                            <button 
                                className="outfit mx-[1em] my-[5px] py-[12px] w-[10em] bg-[#3853A4] text-white text-[16px] font-[500] rounded-[5px]" 
                                onClick={()=>handleDownloadProject()}
                                >
                                Download
                            </button>
                            
     
                        </div>
                    </div>
                    <div>
                        <img
                            className="rounded-[12px] h-[305px] w-full"
                            src={project?.image}
                            alt=""
                        />
                    </div>
                </div>

                {/* session part */}
                <div className="mb-[22px]">
                    <div className="flex items-center justify-between mb-[12px]">
                        <p className="commissioner text-[16px] font-[500]">Sessions</p>

                        {project?.your_role != "viewer" && ( 
                            <button 
                                className=
                                    { my_user?.role === "viewer" ? "hidden" 
                                    :"outfit mx-[1em] my-[5px] py-[12px] w-[10em] bg-[#3853A4] text-white text-[16px] font-[500] rounded-[5px]" }
                                onClick={()=>{setShowSession(true)}}
                                >
                                New session
                            </button>
                        )}

                    </div>
                    <div className="border border-[#3853A4] rounded-[5px]">
                        {project?.sessions?.map((session, index) => (
                            <div className="flex " key={"session-" + index}>
                                <p className="commissioner font-[500] p-2 lg:w-[210px] lg:pl-[17px] lg:py-[20px]">
                                    {session.session_name ? session.session_name : "No Name"}
                                </p>

                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={`${process.env.REACT_APP_API_ADDRESS}/map/?sessionID=${session.session_id}`}
                                    className="commissioner session_bg p-2 lg:w-[111px] lg:py-[20px] text-center font-[400]"
                                >
                                    Join
                                </a>
                                {project?.your_role != "viewer" && ( 
                                    <button
                                        onClick={() => handleDeleteSession(session.session_id)}
                                        className={
                                            my_user?.role === "viewer"
                                                ? "hidden"
                                                : "commissioner session_bg p-2 lg:px-5 lg:py-[20px] text-center font-[400]"
                                        }
                                        >
                                        Delete
                                    </button>
                                )}

                                {/* The button to open modal  details, not a "reactmodal" just some css trick i dont understand*/}
                                <label
                                    className="commissioner session_bg p-2 lg:px-5 lg:py-[20px] text-center font-[400]"
                                    htmlFor={"my-modal" + session.session_id}
                                    >
                                    Details
                                </label>
                                <input
                                    type="checkbox"
                                    id={"my-modal" + session.session_id}
                                    className="modal-toggle"
                                />
                                <label
                                    htmlFor={"my-modal" + session.session_id}
                                    className="modal cursor-pointer"
                                    >
                                    <label className="modal-box relative" htmlFor="">
                                        <p>
                                            Creation Time: {dateFormat(session.creation_time)}
                                        </p>
                                        {
                                            <p className="" key={"details-" + index}>
                                                {session.creator_name != ""
                                                    ? " Creator : " + session.creator_name
                                                    : ""}
                                            </p>
                                        }                                        
                                        <p >
                                            Project Name: {handleProjectName(session.project_id)}
                                        </p>
                                        <p >
                                            Session Name :{" "}
                                            {session.session_name ? session.session_name : "unset"}
                                        </p>
                                        <p>
                                            Description :{" "}
                                            {session.session_description
                                                ? session.session_description
                                                : "unset"}
                                        </p>
                                    </label>
                                </label>
                                <Link to={`/dashboard/session-log/${session.session_id}`}>
                                    <button className="commissioner session_bg lg:w-[111px] lg:py-[20px] p-2 text-center font-[400]">
                                        Log
                                    </button>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
                {/* smb part */}
                <div className="mb-[22px]">
                    <div className="flex items-center justify-between mb-[12px]">
                        <p className="text-[16px] font-[500]">SMB Mounts - warning - bad mounts prevent editing and sessions from running</p>
                        {project?.your_role != "viewer" && ( 
                            <button className="outfit mx-[20px] my-[20px] bg-[#3853A4] p-3 text-white text-[16px] font-[500] rounded-[5px]" 
                                onClick={()=>{setShowSmb(true)}}>
                                    Add SMB Mountpoint
                            </button>
                        )}
                    </div>
                    <div className= {"border border-[#3853A4] rounded-[5px]"}>
                        {project?.mounts?.map((mount) => (
                            <div key={"mount=" + mount.mount} className="flex ">
                                <p className="items-center gap-[11px] font-[500] lg:w-[210px] lg:pl-[17px] lg:py-[20px] p-2">
                                    Address:
                                </p>    
                                <p className="session_bg items-center gap-[11px] font-[500] lg:w-[210px] lg:pl-[17px] lg:py-[20px]">
                                    { mount.server + "/" + mount.share}
                                </p>    
                                <p className="flex items-center gap-[11px] font-[500] lg:w-[210px] lg:pl-[17px] lg:py-[20px] p-2">
                                    Mount: 
                                </p>  
                                <p className="session_bg items-center gap-[11px] font-[500] lg:w-[210px] lg:pl-[17px] lg:py-[20px]">
                                    { mount.mount }
                                </p>                                  
                                <button
                                    onClick={() => handleDeleteSmb(mount.mount)}
                                    className={
                                        my_user?.role === "viewer"
                                            ? "hidden"
                                            : "commissioner session_bg p-2 lg:px-5 lg:py-[20px] text-center font-[400]"
                                    }
                                    >
                                    Delete
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
                {/* user part */}
                <div className="mb-[22px]">
                    <div className="flex items-center justify-between mb-[12px]">
                        <p className="text-[16px] font-[500]">Users</p>
                        {project?.your_role != "viewer" && ( 
                            <button className="outfit mx-[20px] my-[20px] bg-[#3853A4] p-3 text-white text-[16px] font-[500] rounded-[5px]" 
                                onClick={()=>{setShowShare(true)}}>
                                    Share
                            </button>
                        )}
                    </div>
                    <div className= {project?.your_role == "viewer" ?  "flex border border-[#3853A4] rounded-[5px]" : "border border-[#3853A4] rounded-[5px]"}>
                        {project?.users?.map((puser) => (
                            <div key={"user-" + puser.user_id} className="flex ">
                                <p className="flex items-center gap-[11px] font-[500] lg:w-[210px] lg:pl-[17px] lg:py-[20px] p-2">
                                    <img width={36} src={puser.image} alt="" /> {puser.username}
                                </p>
                                {project?.your_role != "viewer" && ( 
                                    <>
                                        <p
                                            className={
                                                my_user?.role === "viewer"
                                                    ? "hidden"
                                                    : " session_bg lg:w-[257px] lg:py-[20px] p-2 text-center font-[400] cursor-pointer"
                                            }
                                            onClick={() =>
                                                puser.user_id === my_user?.user_id
                                                    ? handleLeave(project.project_id)
                                                    : handleUserRemove(project.project_id, puser.user_id)
                                            }
                                            >
                                            {puser.user_id === my_user?.user_id ? "Leave" : "Remove"}
                                        </p>
                                        <select
                                            className={
                                                my_user?.role === "viewer"
                                                    ? "hidden"
                                                    : " session_bg lg:w-[257px] lg:py-[20px] p-2 text-center font-[400] cursor-pointer"
                                            }
                                            defaultValue = {puser.role}
                                            onChange = {event=>handleChangeUserRole(event, puser)}
                                            >
                                            <option id="analyst">analyst</option>
                                            <option id="viewer">viewer</option>
                                        </select>
                                    </>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
                {/* recent activity part */}
                <div className="mb-[22px]">
                    <div className="flex items-center justify-between mb-[12px]">
                        <p className="text-[16px] font-[500]">Recent Activity</p>
                        <button 
                            className="outfit mx-[20px] my-[20px] bg-[#3853A4] p-3 text-white text-[16px] font-[500] rounded-[5px]" 
                            onClick={() =>navigate(`/dashboard/project-log/${id}`)}
                            >
                                Full report
                        </button>
                    </div>
                    <div className="border border-[#3853A4] rounded-[5px] pb-[56px] px-4">
                        {projectLog.map((i, index) => (
                            <p
                                key={"log-" + index}
                                dangerouslySetInnerHTML={{
                                    __html: i.log,
                                }}
                            ></p>
                        ))}
                    </div>
                </div>
            </div>
        </div>
        :<div style={divStyle}> <ToastContainer /><h1>PROJECT NOT FOUND</h1> <p> Perhaps the project was deleted or you are trying to access a project that does not exist</p></div>
    );
};

export default ProjectDetails;
