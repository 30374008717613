// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Outfit:wght@200;300;400;500;600;700;800;900&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Commissioner:wght@100;200;300;400;500;600;700;800;900&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* body {
  font-family: "Outfit", sans-serif;
  font-family: "Commissioner", sans-serif;
} */

.outfit {
  font-family: "Outfit", sans-serif;
}
.commissioner {
  font-family: "Commissioner", sans-serif;
}
.poppins{
  font-family: 'Poppins', sans-serif;
}
label.commissioner:active{
  background-color: unset;
  color: black;
}
button.commissioner:active{
  background-color: unset;
  color: black;
}
p.commissioner:active{
  background-color: unset;
  color: black;
}
a.commissioner:active{
  background-color: unset;
  color: black;
}

`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAGA;;;GAGG;;AAEH;EACE,iCAAiC;AACnC;AACA;EACE,uCAAuC;AACzC;AACA;EACE,kCAAkC;AACpC;AACA;EACE,uBAAuB;EACvB,YAAY;AACd;AACA;EACE,uBAAuB;EACvB,YAAY;AACd;AACA;EACE,uBAAuB;EACvB,YAAY;AACd;AACA;EACE,uBAAuB;EACvB,YAAY;AACd","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Outfit:wght@200;300;400;500;600;700;800;900&display=swap\");\n@import url(\"https://fonts.googleapis.com/css2?family=Commissioner:wght@100;200;300;400;500;600;700;800;900&display=swap\");\n@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');\n/* body {\n  font-family: \"Outfit\", sans-serif;\n  font-family: \"Commissioner\", sans-serif;\n} */\n\n.outfit {\n  font-family: \"Outfit\", sans-serif;\n}\n.commissioner {\n  font-family: \"Commissioner\", sans-serif;\n}\n.poppins{\n  font-family: 'Poppins', sans-serif;\n}\nlabel.commissioner:active{\n  background-color: unset;\n  color: black;\n}\nbutton.commissioner:active{\n  background-color: unset;\n  color: black;\n}\np.commissioner:active{\n  background-color: unset;\n  color: black;\n}\na.commissioner:active{\n  background-color: unset;\n  color: black;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
