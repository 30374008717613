import React, { useEffect, useState } from "react";
import detectTimeout from "../../utils/detectTimeout";
import axiosInstance from "../../utils/axiosInstance";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddUser = ({toastAlert, finalAction, projectId }) => {

    const [searchText, SetSearchText] = useState("");
    const { id } = useParams();
    const [ userList, setUserList ] = useState([]);
    const [user, setUser] = useState("");
    const [role, setRole] = useState("");

    const newId = id ? id: projectId

    //this one displays on top of the overlay
    const my_toastAlert = (msg) => toast(msg, {containerId:'AddUser'});

    const navigate = useNavigate();

    const handle_err_response = (res) => {
        if (!detectTimeout(res, navigate)) 
            { 
                toastAlert(res.response.data.message);
                finalAction();
            }
    }

    useEffect(() => {
        axiosInstance
            .post("/api/user/list", {filter:searchText, no_admin:true})
            .then((response) => setUserList(response.data.users))           
            .catch(handle_err_response)
    }, [searchText]);

    const handleAccept = () => {
        const data = {
            user_id: user?.user_id,
            role,
        };
        axiosInstance
            .post(`/api/project/add_user/${newId}`, data)
            .then((res) => {             
                toastAlert(res.data.message);
                finalAction();
            })
            .catch(handle_err_response);
    };

    return (
        <div className="addUser bg-white lg:rounded-l-[50px]    w-full    xl:h-full flex justify-center items-center">
            <ToastContainer enableMultiContainer containerId={'AddUser'}/>
            <div className="addUser__inner p-3 lg:p-12 max-w-[900px] border-[1px] rounded-[16px] border-[#d5d5d5]">
                <h1 className="text-center text-3xl font-bold mb-8">Add User</h1>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
                    <div className="bg-[#F8FAFF] p-3 lg:p-6 rounded-lg lg:rounded-[40px] overflow-hidden">
                        <input
                            type="search"
                            className="search_bg border h-[40px] rounded-[36px] pl-10 w-full mb-3"
                            placeholder="Search"
                            onChange={(e) => SetSearchText(e.target.value)}
                        />

                        <div className="h-[368px] overflow-y-scroll">
                            {userList &&
                                userList.length > 0 &&
                                userList?.map((i, index) => (
                                    <div
                                        key={index}
                                        className={`flex items-center gap-4 relative mb-[12px] hover:bg-gray-200 cursor-pointer ${
                                            user?.user_id === i.user_id && "bg-gray-200"
                                        }`}
                                        onClick={() => setUser(i)}
                                    >
                                        <div className="avatar online">
                                            <div className="w-8 lg:w-16 rounded-full">
                                                <img src={i.image} alt="" />
                                            </div>
                                        </div>

                                        <div>
                                            <p className="poppins text-[15px] lg:text-[20px] font-[600]">
                                                {i.username}
                                            </p>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>

                    <div className="my-auto">
                        <div className="flex    flex-col ">
                            <div className="dropdown dropdown-right">
                                <label tabIndex={0}>
                                    {" "}
                                    <button className="lex justify-center text-[20px] p-[8px] mb-[10px]">
                                        Select Role:
                                    </button>
                                </label>
                                <ul
                                    tabIndex={0}
                                    className="bg-base-100 rounded-box w-52 right-[0]"
                                >
                                    <li>
                                        <a className="commissioner text-[#3853A4] hover:bg-transparent ">
                                            <button
                                                className={`${
                                                    role === "analyst" && "bg-gray-300"
                                                } commissioner min-w-[121px] bg-[#F1F6FF] text-[20px] font-[500] rounded-[8px] p-[8px] mb-[10px] `}
                                                onClick={() => setRole("analyst")}
                                            >
                                                Analyst
                                            </button>
                                        </a>
                                    </li>
                                    <li>
                                        <a className="commissioner text-[#3853A4] hover:bg-transparent">
                                            {" "}
                                            <button
                                                className={`${
                                                    role === "viewer" && "bg-gray-300"
                                                } commissioner min-w-[121px] bg-[#F1F6FF] text-[20px] font-[500] rounded-[8px] p-[8px] mb-[10px]`}
                                                onClick={() => setRole("viewer")}
                                            >
                                                Viewer
                                            </button>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>                        
                    </div>               
                </div>
                <div className="flex    flex-col ">
                        <button
                                onClick={() => {role === "" ? my_toastAlert("You have to select a role first! ") : handleAccept();}}
                                className="commissioner min-w-[121px] bg-[#F1F6FF] text-[20px] leading-[38px font-[500] rounded-[8px] p-[8px] mb-[10px]"
                            >
                                Accept
                        </button>
                    </div>
            </div>
        </div>
    );
};

export default AddUser;
