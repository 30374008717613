import React, { useRef, useState } from "react";
import axiosInstance from "../../utils/axiosInstance";
import { useNavigate } from "react-router-dom";
import detectTimeout from "../../utils/detectTimeout";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//import UploadFolder from "./UploadFolder";

const ProjectUpload = ({toastAlert, finalAction}) => {
    const navigate = useNavigate();
    const inputRef = useRef();
    const [base64Image, setBase64Image] = useState("");
    const [project_name, setProjectName] = useState("");
    const [project_description, setProjectDescription] = useState("");
    const [zipFile, setZipFile] = useState("");

    
    const my_toastAlert = (msg) => toast(msg, {containerId: "UploadZip"});

    // img base64 convert start
    const handleImageChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            setBase64Image(reader.result);
        };

        reader.readAsDataURL(file);
    };
    // img base64 convert end

    const handle_err_response = (res) => {
        if (!detectTimeout(res, navigate))
            {my_toastAlert(res.response.data.message);}
    }

    // submit form start
    const handleSubmit = () => {

        const info = {
            project_name,
            file_data: zipFile,
            project_image: base64Image,
            project_description,
            project_path_to_execute: "",
        };

        axiosInstance
            .post("/api/project/create", info)
            .then((res) => {
                //force the editor open for a quick moment to update the zip filename (it has the power to unzip this file)
                axiosInstance
                    .post("/api/project/start_editing/"+res.data.project_id, {update_zip_only:true})
                    .catch(err=>{
                        toastAlert(err)
                        handle_err_response(err)
                    });
                finalAction();
            })
            .catch(handle_err_response);
    };

    // image upload:

    const onFileChangeCapture = (e) => {
        
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            setBase64Image(reader.result);
        };

        reader.readAsDataURL(file);
    };

    const inputHandler = () => {
        inputRef.current.click();
    };

    //for the zip file:  
    const onFileChange = (e) => {
        const name = e.target.files[0].name;

        const reader = new FileReader();
        reader.addEventListener('load', (event) => {   
            setZipFile(event.currentTarget.result);
        })
        reader.readAsDataURL(e.target.files[0]);

    };

    return (
        <div>
        <div className="bg-white lg:rounded-l-[50px] project__copy max-w-[700px] mx-auto h-full flex justify-center items-center">
            <ToastContainer enableMultiContainer containerId={"UploadZip"}/>
            <div className="copy__inner    border-[1px] rounded-[8px] bordered-[#F8FAFF] shadow-black px-[28px] py-[20px] flex flex-col items-center w-full">
                <h1 className="text-center text-3xl font-bold mb-5">Upload Project (zip file)</h1>
                <div >
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-3    my-[20px]">
                        <input
                            className="border px-3 py-2 w-full rounded-lg"
                            type="text"
                            placeholder="Project Name"
                            name="project_name"
                            value={project_name}
                            onChange={(e) => setProjectName(e.target.value)}
                        />

                        <div className="">
                            <input 
                                type="file" id="myFile" name="filename"
                                onChange={onFileChange}/>                          
                        </div>
                    </div>
                    <div>
                        <input
                            className="border px-3 w-full    py-2 rounded-lg mb-[20px]"
                            type="text"
                            placeholder="Project description"
                            name="project_description"
                            value={project_description}
                            onChange={(e) => setProjectDescription(e.target.value)}
                        />
                    </div>
                    <div className="copy__img border-[1px] overflow-hidden w-[302px] bordered rounded-[8px]">
                    <input
                        type="file"
                        ref={inputRef}
                        accept="image/*"
                        onChangeCapture={onFileChangeCapture}
                        className="file-input w-full hidden    border-0 bg-white"
                    />
                 
                    <div
                        className="profile relative cursor-pointer "
                        >
                        {base64Image &&<img
                            className="relative z-[99] h-[192px] w-full"
                            src={base64Image}
                            alt="image"
                        />   }  
                        <button 
                            className="underline commissioner w-full bg-[#F8FAFF] text-[18px] font-[500] text-center py-[15px] mt-[-10px] z-[9]"
                            onClick={inputHandler}
                            >
                            Pick new image
                        </button>
                        <button 
                            className="underline commissioner w-full bg-[#F8FAFF] text-[18px] font-[500] text-center py-[15px] mt-[-10px] z-[9]"
                            onClick={()=>setBase64Image("")}
                            >                         
                            Use random image
                        </button>
                  
                    </div></div>

                    <div className="text-center">
                        <button
                            className="commissioner min-w-[121px] bg-[#F1F6FF] text-[20px] leading-[38px font-[500] rounded-[8px] p-[8px] mb-[10px] my-4"
                            onClick={handleSubmit}
                            >
                            Accept
                        </button>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
};

export default ProjectUpload;
