import axios from "axios";
const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_ADDRESS,
    withCredentials: true,
    headers: {
        post : {
            ['Content-Type']: 'application/json',
            ["Access-Control-Allow-Credentials"]: true,
        }
    }
});
export default axiosInstance;
