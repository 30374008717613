// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login_container {
  max-width: 650px;
  margin: auto;
  padding: 44px 63px 72px;
  background: #ffffff;
  /* box-shadow: 0px 1px 20px -8px rgba(0, 0, 0, 0.1); */
  border-radius: 15px;
}
.key {
  position: relative;
}
.key::after {
  position: absolute;
  content: "";
  height: 1px;
  line-height: 20px;
  width: 100%;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #222222;
}

.pointer{
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/Register/Register.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,YAAY;EACZ,uBAAuB;EACvB,mBAAmB;EACnB,sDAAsD;EACtD,mBAAmB;AACrB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,WAAW;EACX,iBAAiB;EACjB,WAAW;EACX,SAAS;EACT,SAAS;EACT,2BAA2B;EAC3B,yBAAyB;AAC3B;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".login_container {\r\n  max-width: 650px;\r\n  margin: auto;\r\n  padding: 44px 63px 72px;\r\n  background: #ffffff;\r\n  /* box-shadow: 0px 1px 20px -8px rgba(0, 0, 0, 0.1); */\r\n  border-radius: 15px;\r\n}\r\n.key {\r\n  position: relative;\r\n}\r\n.key::after {\r\n  position: absolute;\r\n  content: \"\";\r\n  height: 1px;\r\n  line-height: 20px;\r\n  width: 100%;\r\n  bottom: 0;\r\n  left: 50%;\r\n  transform: translateX(-50%);\r\n  background-color: #222222;\r\n}\r\n\r\n.pointer{\r\n  cursor: pointer;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
