import axiosInstance from "../utils/axiosInstance";
import React, { createContext, useEffect, useState } from "react";
import detectTimeout from "../utils/detectTimeout";
import { useNavigate, useParams } from "react-router-dom";

const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const [my_user, setMyUser] = useState([]);

    const handle_err_response = (res) => {
        detectTimeout(res, navigate)
    }  

    useEffect(() => {
        axiosInstance
            .post("/api/user/")
            .then((response) => setMyUser(response.data.user))
            .catch(handle_err_response);
    }, []);

    const userInfo = {
        my_user,
        setMyUser,
    };
    return (
        <div>
            <UserContext.Provider value={userInfo}>{children}</UserContext.Provider>
        </div>
    );
};

export default AuthProvider;
export const UserContext = createContext();