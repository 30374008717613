import axiosInstance from "../../utils/axiosInstance";
import React, { useEffect, useState } from "react";
import detectTimeout from "../../utils/detectTimeout";
import { useNavigate, useParams } from "react-router-dom";

const SessionLog = () => {
    const [sessionLog, setsessionLog] = useState([]);
    const { id } = useParams();
    const navigate = useNavigate();

    const handle_err_response = (res) => {
        if (!detectTimeout(res, navigate))
        {}
}
    
    useEffect(() => {
        axiosInstance
            .post(`/api/session/logs/${id}`)
            .then((response) => setsessionLog(response.data.logs))
            .catch(handle_err_response);
    }, [id]);

    return (
        <div className="node__info bg-[#FFFBFB] lg:rounded-l-[50px] h-full overflow-x-auto lg:py-[20px] lg:pt-[20px] lg:px-[20px] p-4">
            <h1 className="text-3xl font-bold mb-5">Session Logs</h1>
            <p
                className="text-lg font-[600]"
                dangerouslySetInnerHTML={{
                    __html: sessionLog,
                }}
            ></p>
        </div>
    );
};

export default SessionLog;
